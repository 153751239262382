<template>
  <div>
    <EntityDataTableCard
      id="tbl-tax-engine-connections-list"
      title="Tax Engine Connections"
      :loading="loading"
      :items="entities"
      :fields="fields"
      :per-page="10"
      export-disabled
      import-disabled
      @create="handleCreate"
      @edit="handleEdit"
      @delete="handleDelete"
    >
      <template #engineTypeName="{ item }">
        <TaxEngineBadge :value="item.taxEngineType || item.engineType" />
      </template>
      <template #hostname="{ item }">
        <span :title="item.url" class="text-small no-wrap">
          {{ item.hostname }}
        </span>
      </template>
      <template #no-items-view>
        <div v-if="!loading" class="w-100 d-flex">
          <CButton
            id="btn-create-first-connection"
            block
            size="lg"
            shape="square"
            variant="outline"
            color="primary"
            style="margin: auto; padding: 2rem"
            @click="
              () => {
                taxEngineConnection = null;
                showEdit = true;
              }
            "
            >Click to Create Your First Connection</CButton
          >
        </div>
      </template>
    </EntityDataTableCard>
    <EditTaxEngineConnectionModal
      v-if="showEdit"
      id="mod-edit-tax-engine-connection"
      :show="showEdit"
      :value="taxEngineConnection"
      @refresh="refreshTable"
      @update:show="
        (v) => {
          showEdit = v;
        }
      "
    />
  </div>
</template>

<script>
import EntityDataTableCard from '@/components/EntityDataTableCard';
import EditTaxEngineConnectionModal from '@/components/modal/EditTaxEngineConnectionModal';
import { DATA_TABLE_FIELDS } from '@/components/MDataTable';
import { mapGetters } from 'vuex';

export default {
  name: 'TaxEngineConnectionsTable',
  components: {
    EntityDataTableCard,
    EditTaxEngineConnectionModal,
  },
  data() {
    return {
      loading: false,
      showEdit: false,
      taxEngineConnection: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['hasSingleTaxEngine']),
    ...mapGetters('testing', ['connections']),
    fields() {
      let options = [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'engineTypeName',
          label: 'Engine',
        },
        {
          key: 'hostname',
          label: 'Host',
        },
        DATA_TABLE_FIELDS.actions,
      ];

      if (this.hasSingleTaxEngine) {
        options.splice(1, 1);
      }

      return Object.freeze(options);
    },
    entities() {
      return this.connections.map((o) => {
        let urlParts = o.url.split('://');
        let hostname = urlParts[1].split('/')[0];

        return Object.freeze({
          ...o,
          hostname,
          engineTypeName: this.getEngineType(o.engineType || o.taxEngineType),
          engineTypeColor: this.getEngineTypeColor(
            o.engineType || o.taxEngineType,
          ),
        });
      });
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    refreshTable() {
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      this.$store.dispatch('testing/fetchConnections').finally(() => {
        this.$nextTick(() => {
          this.loading = false;
        });
      });
    },
    getEngineType(value) {
      return this.$t(`taxEngineType.${value}`);
    },
    getEngineTypeColor(value) {
      switch (value?.toUpperCase()) {
        case 'VTX':
          return 'primary';
        case 'SBX':
          return 'info';
        default:
          return 'secondary';
      }
    },
    handleEdit(taxConnection) {
      this.taxEngineConnection = taxConnection;
      this.showEdit = true;
    },
    handleDelete(taxConnection) {
      const self = this;
      this.$swal
        .fire({
          title: 'Confirm Delete',
          text: 'Are you sure you wish to delete this connection?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        })
        .then((res) => {
          if (res.isConfirmed) {
            self.loading = true;
            this.$store
              .dispatch('testing/deleteConnection', taxConnection.id)
              .then(() => {
                self.$emit('remove', taxConnection.id);
              })
              .catch((err) => {
                this.$log.error(err);
                this.$swal.fire({
                  title: 'Error',
                  text: 'The connection could not be deleted',
                  icon: 'error',
                });
              })
              .finally(() => {
                self.$nextTick(() => {
                  self.loading = false;
                });
              });
          }
        });
    },
    handleCreate() {
      this.taxEngineConnection = null;
      this.showEdit = true;
    },
  },
};
</script>
