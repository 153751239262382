<template>
  <div class="dashboard-container">
    <CJumbotron color="white">
      <CRow>
        <CCol>
          <CImg :src="$brand('modios-rapid-testing.png', '/img')" width="280" />
        </CCol>
      </CRow>
    </CJumbotron>
    <CRow>
      <CCol>
        <TaxEngineConnectionsTable />
      </CCol>
      <CCol>
        <TestDeckTable
          @remove="handleTestDeckRemoval"
          @run-test-deck="
            (id) => {
              testDeckId = id;
              showTestDeckRunModal = true;
            }
          "
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <RecentTestResultsTable ref="testResults" />
      </CCol>
    </CRow>
    <RunTestDeckModal
      v-if="showTestDeckRunModal"
      :show.sync="showTestDeckRunModal"
      :test-deck-id="testDeckId"
      @refresh="handleTestDeckExecuted"
    />
  </div>
</template>

<script>
import RunTestDeckModal from '@/components/TestDeck/RunTestDeckModal';
import RecentTestResultsTable from '@/views/testing/_components/RecentTestResultsTable';
import TaxEngineConnectionsTable from '@/views/testing/_components/TaxEngineConnectionsTable';
import TestDeckTable from '@/views/testing/_components/TestDeckTable';

export default {
  name: 'TestingDashboard',
  components: {
    RecentTestResultsTable,
    TaxEngineConnectionsTable,
    TestDeckTable,
    RunTestDeckModal,
  },
  data() {
    return {
      showTestDeckRunModal: false,
      testDeckId: null,
    };
  },
  methods: {
    handleTestDeckRemoval(id) {
      this.$refs['testResults'].removeForTestDeck(id);
    },
    handleTestDeckExecuted() {
      this.$refs['testResults'].fetchData();
    },
  },
};
</script>
