var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.canRunTests || _vm.entities.size > 0)?_c('EntityDataTableCard',{attrs:{"id":"test-results-list-table","title":"Test Results","loading":_vm.loading,"items":_vm.entities,"fields":_vm.fields,"per-page":25,"export-disabled":"","import-disabled":"","add-disabled":"","table-filter":false,"table-filter-value":_vm.tableFilterValue},on:{"update:tableFilterValue":function($event){_vm.tableFilterValue=$event},"update:table-filter-value":function($event){_vm.tableFilterValue=$event}},scopedSlots:_vm._u([{key:"custom-header-action",fn:function(){return [_c('CButton',{staticClass:"btn-crd btn-add",attrs:{"id":"btn-test-result-refresh","variant":"ghost","color":"info","title":_vm.autoRefreshing
            ? 'Auto-Refreshing'
            : _vm.loading
            ? 'Refreshing'
            : 'Refresh',"disabled":_vm.loading},on:{"click":function($event){return _vm.fetchData()}}},[_c('CIcon',{class:{ spinning: _vm.autoRefreshing || _vm.loading },attrs:{"name":"cil-reload"}})],1),_c('MRunButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.canRunTests),expression:"canRunTests"}],staticClass:"mx-1",attrs:{"id":"btn-create-new-test-run"},on:{"click":_vm.handleCreate}})]},proxy:true},{key:"transactionGroupName",fn:function(ref){
            var item = ref.item;
return [(item.runStatus.toUpperCase() === 'COMPLETED')?_c('router-link',{attrs:{"id":("lnk-test-results-details_" + (item.id)),"to":{ name: 'TestResults', params: { testRunId: item.id } }}},[_vm._v(_vm._s(item.transactionGroupName))]):[_vm._v(" "+_vm._s(item.transactionGroupName)+" ")]]}},{key:"taxEngineTypeLabel",fn:function(ref){
            var item = ref.item;
return [_c('TaxEngineBadge',{attrs:{"value":item.taxEngineType}})]}},{key:"runStatus",fn:function(ref){
            var item = ref.item;
return [_c('CBadge',{attrs:{"color":_vm.getRunStatusColor(item.runStatus)}},[_vm._v(_vm._s(item.runStatus))])]}},{key:"custom-actions",fn:function(ref){
            var item = ref.item;
return [(
          item.runStatus.toUpperCase() !== 'IN_PROGRESS' &&
          item.runStatus.toUpperCase() !== 'PENDING'
        )?_c('MDeleteButton',{staticClass:"mx-1",attrs:{"id":("btn-delete-test-result_" + (item.id)),"variant":"outline"},on:{"click":function($event){return _vm.handleDelete(item)}}}):_c('MDeleteButton',{staticClass:"mx-1",attrs:{"id":("btn-delete-test-result_" + (item.id)),"variant":"outline","title":"Cancel"},on:{"click":function($event){return _vm.handleCancel(item)}}},[_c('CIcon',{attrs:{"name":"cis-media-stop-circle"}})],1),(item.runStatus.toUpperCase() === 'COMPLETED')?_c('MTestResultDownloadButton',{staticClass:"mx-1",attrs:{"id":("btn-test-run-download_" + (item.id)),"test-run-id":item.id,"variant":"outline","title":"Download"}}):_vm._e()]}}],null,false,3022318387)}):_vm._e(),(_vm.showModal)?_c('TestRunModal',{attrs:{"id":"mod-test-run-modal","show":_vm.showModal},on:{"refresh":_vm.refreshTable,"update:show":function (v) {
        _vm.showModal = v;
      }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }