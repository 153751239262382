var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('EntityDataTableCard',{attrs:{"id":"tbl-test-decks-list","title":"Test Decks","items":_vm.content,"fields":_vm.fields,"is-loading":_vm.isLoading,"import-disabled":"","export-disabled":""},on:{"create":_vm.handleImport},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"id":("lnk-test-deck-details_" + (item.id)),"to":{
          name: 'TestDeckDetails',
          params: { testDeckId: item.id },
        }}},[_vm._v(_vm._s(item.name))])]}},{key:"taxEngineTypeLabel",fn:function(ref){
        var item = ref.item;
return [_c('TaxEngineBadge',{attrs:{"value":item.taxEngineType}})]}},{key:"filename",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-small"},[_vm._v(_vm._s(item.filename))])]}},{key:"custom-actions",fn:function(ref){
        var item = ref.item;
return [_c('MTestDeckRunButton',{staticClass:"mr-1",attrs:{"test-deck-id":item.id},on:{"click":function($event){return _vm.$emit('run-test-deck', item.id)}}}),_c('MTestDeckDownloadButton',{attrs:{"id":("btn-download-list_" + (item.id)),"test-deck-id":item.id}}),_c('MTestDeckDeleteButton',{attrs:{"id":("btn-delete-list_" + (item.id)),"test-deck-id":item.id},on:{"deleted":function($event){return _vm.handleDelete(item)}}})]}},{key:"no-items-view",fn:function(){return [(!_vm.isLoading)?_c('div',{staticClass:"w-100 d-flex"},[_c('CButton',{staticStyle:{"margin":"auto","padding":"2rem"},attrs:{"id":"btn-create-first-test-deck","block":"","size":"lg","shape":"square","variant":"outline","color":"primary"},on:{"click":function () { return (_vm.showImport = true); }}},[_vm._v("Click to Import Your First Test Deck")])],1):_vm._e()]},proxy:true}])}),(_vm.showImport)?_c('ImportTestDecksModal',{attrs:{"id":"mod-import-test-deck","show":_vm.showImport},on:{"update:show":function($event){_vm.showImport=$event},"imported":function () { return _vm.fetchData(); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }