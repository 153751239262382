var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('EntityDataTableCard',{attrs:{"id":"tbl-tax-engine-connections-list","title":"Tax Engine Connections","loading":_vm.loading,"items":_vm.entities,"fields":_vm.fields,"per-page":10,"export-disabled":"","import-disabled":""},on:{"create":_vm.handleCreate,"edit":_vm.handleEdit,"delete":_vm.handleDelete},scopedSlots:_vm._u([{key:"engineTypeName",fn:function(ref){
var item = ref.item;
return [_c('TaxEngineBadge',{attrs:{"value":item.taxEngineType || item.engineType}})]}},{key:"hostname",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-small no-wrap",attrs:{"title":item.url}},[_vm._v(" "+_vm._s(item.hostname)+" ")])]}},{key:"no-items-view",fn:function(){return [(!_vm.loading)?_c('div',{staticClass:"w-100 d-flex"},[_c('CButton',{staticStyle:{"margin":"auto","padding":"2rem"},attrs:{"id":"btn-create-first-connection","block":"","size":"lg","shape":"square","variant":"outline","color":"primary"},on:{"click":function () {
              _vm.taxEngineConnection = null;
              _vm.showEdit = true;
            }}},[_vm._v("Click to Create Your First Connection")])],1):_vm._e()]},proxy:true}])}),(_vm.showEdit)?_c('EditTaxEngineConnectionModal',{attrs:{"id":"mod-edit-tax-engine-connection","show":_vm.showEdit,"value":_vm.taxEngineConnection},on:{"refresh":_vm.refreshTable,"update:show":function (v) {
        _vm.showEdit = v;
      }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }