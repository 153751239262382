<template>
  <div>
    <EntityDataTableCard
      id="tbl-test-decks-list"
      title="Test Decks"
      :items="content"
      :fields="fields"
      :is-loading="isLoading"
      import-disabled
      export-disabled
      @create="handleImport"
    >
      <template #name="{ item }">
        <router-link
          :id="`lnk-test-deck-details_${item.id}`"
          :to="{
            name: 'TestDeckDetails',
            params: { testDeckId: item.id },
          }"
          >{{ item.name }}</router-link
        >
      </template>
      <template #taxEngineTypeLabel="{ item }">
        <TaxEngineBadge :value="item.taxEngineType" />
      </template>
      <template #filename="{ item }">
        <span class="text-small">{{ item.filename }}</span>
      </template>
      <template #custom-actions="{ item }">
        <MTestDeckRunButton
          :test-deck-id="item.id"
          class="mr-1"
          @click="$emit('run-test-deck', item.id)"
        />

        <MTestDeckDownloadButton
          :id="`btn-download-list_${item.id}`"
          :test-deck-id="item.id"
        />

        <MTestDeckDeleteButton
          :id="`btn-delete-list_${item.id}`"
          :test-deck-id="item.id"
          @deleted="handleDelete(item)"
        />
      </template>
      <template #no-items-view>
        <div v-if="!isLoading" class="w-100 d-flex">
          <CButton
            id="btn-create-first-test-deck"
            block
            size="lg"
            shape="square"
            variant="outline"
            color="primary"
            style="margin: auto; padding: 2rem"
            @click="() => (showImport = true)"
            >Click to Import Your First Test Deck</CButton
          >
        </div>
      </template>
    </EntityDataTableCard>
    <ImportTestDecksModal
      v-if="showImport"
      id="mod-import-test-deck"
      :show.sync="showImport"
      @imported="() => fetchData()"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  ImportTestDecksModal,
  MTestDeckDeleteButton,
  MTestDeckDownloadButton,
  MTestDeckRunButton,
} from '@/components/TestDeck';

const TEST_DECK_FIELDS = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'taxEngineTypeLabel',
    label: 'Engine',
  },
  {
    key: 'filename',
    label: 'Filename',
    hidden: true,
  },
  {
    key: 'itemCount',
    label: 'Items',
  },
  {
    key: 'createdAt',
    label: 'Created At',
    fieldType: 'DATE',
    format: 'yyyy-MM-dd HH:MM',
    hidden: true,
  },
  {
    key: 'custom-actions',
    label: '',
    sorter: false,
    filter: false,
    _classes: ['action-cell'],
    toggleable: false,
  },
];

export default {
  name: 'TestDeckList',
  components: {
    ImportTestDecksModal,
    MTestDeckDeleteButton,
    MTestDeckDownloadButton,
    MTestDeckRunButton,
  },
  data() {
    return {
      isLoading: false,
      showImport: false,
      watcher: null,
    };
  },

  computed: {
    ...mapGetters('auth', ['hasSingleTaxEngine']),
    ...mapGetters('testing', ['testDecks']),
    fields() {
      let opts = [...TEST_DECK_FIELDS];
      if (this.hasSingleTaxEngine) {
        opts.splice(1, 1);
      }
      return Object.freeze(opts);
    },
    content() {
      return this.testDecks
        .filter((deck) => {
          return deck.fileType.toUpperCase() !== 'REGVAL_BATCH';
        })
        .map((deck) => {
          const taxEngineType = deck.fileType.substring(0, 3).toUpperCase();

          return Object.freeze({
            id: deck.id,
            name: deck.name,
            filename: deck.filename,
            fileType: deck.fileType,
            itemCount: deck.itemCount,
            createdAt: deck.createdAt,
            taxEngineType,
            taxEngineTypeLabel: this.$t(`taxEngineType.${taxEngineType}`),
          });
        });
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.$store.dispatch('testing/fetchTestDecks').finally(() => {
        this.$nextTick(() => {
          this.isLoading = false;
        });
      });
    },
    handleImport() {
      this.showImport = true;
    },
    handleDelete() {
      this.fetchData();
      /*
      const id = item.id;
      const index = this.content.findIndex((row) => {
        return row.id === id;
      });
      if (index >= 0) {
        this.content.splice(index, 1);
      }
      this.$emit('remove', id);
      */
    },
  },
};
</script>
