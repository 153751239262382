<template>
  <CModal :show.sync="modalShow" size="lg" centered>
    <template #header>
      <h2>Test Run</h2>
    </template>
    <CForm :class="{ 'is-loading': isLoading }" @submit.prevent="handleRun">
      <CRow>
        <CCol>
          <MSelect
            id="execute-test-run_connectivityId"
            label="Tax Engine Connection"
            placeholder="Connection Name"
            required
            :loading="connectionsLoading"
            :options="connectionsOptions"
            :value.sync="connectivityId"
          />
        </CCol>
        <CCol class="pt-2 align-self-center">
          {{
            preview.url.length > 100
              ? `${preview.url.slice(0, 50)}...`
              : preview.url
          }}
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <MSelect
            id="execute-test-run_groupId"
            label="Test Deck"
            placeholder="Test Deck Name"
            required
            :loading="testDecksLoading"
            :options="testDecksOptions"
            :value.sync="groupId"
          />
        </CCol>
        <CCol class="pt-4 align-self-center">
          <p v-if="groupId.length > 0" id="execute-test-run_groupCount">
            {{ $format.number(preview.numberOfTests) }} tests in group
          </p>
          <p
            v-if="
              groupId.length > 0 &&
              !!preview.testDescription &&
              preview.testDescription.length > 0
            "
            id="execute-test-run_groupDescription"
          >
            {{ preview.testDescription }}
          </p>
        </CCol>
      </CRow>
      <CRow>
        <CCol col="6">
          <MDatePicker
            id="execute-test-run_transactionDate"
            :value.sync="transactionDate"
          />
        </CCol>
      </CRow>
    </CForm>
    <template #footer>
      <CButton
        id="btn-execute-test-run_cancel"
        color="secondary"
        @click="handleCancel"
        >Cancel</CButton
      >
      <CButton id="execute-test-run_submit" color="primary" @click="handleRun"
        >Run</CButton
      >
    </template>
  </CModal>
</template>
<script>
import api from '@/api';
import { MDatePicker } from '@/components';
import { formsMixin } from '@/mixins/forms-mixin';
import { mapGetters } from 'vuex';
export default {
  name: 'TestRunModal',
  components: {
    MDatePicker,
  },
  mixins: [formsMixin],
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      showState: this.show,
      isLoading: false,
      preview: {
        url: '',
        numberOfTests: '',
        testDescription: '',
      },
      connectivityId: '',
      groupId: '',
      transactionDate: new Date(),
      selectedTaxEngineType: '',
    };
  },
  computed: {
    ...mapGetters('testing', [
      'connections',
      'testDecks',
      'connectionsLoading',
      'testDecksLoading',
    ]),
    modalShow: {
      get() {
        return this.showState;
      },
      set(v) {
        this.showState = v;
        this.$emit('update:show', v === true);
      },
    },
    testDecksOptions() {
      let typeFilter = false;
      if (this.selectedTaxEngineType) {
        typeFilter = this.selectedTaxEngineType.toLowerCase();
      }
      return this.testDecks
        .filter((o) => {
          return !typeFilter || o.fileType.toLowerCase().startsWith(typeFilter);
        })
        .map((o) => {
          return Object.freeze({
            value: o.id,
            label: o.name,
          });
        });
    },
    connectionsOptions() {
      return this.connections.map((o) => {
        return Object.freeze({
          value: o.id,
          label: o.name,
        });
      });
    },
  },
  watch: {
    connectivityId(value) {
      let connection = this.connections.find((c) => c.id === value);
      if (connection) {
        let url = connection.url;
        if (connection.description && connection.description.length > 0) {
          this.preview.url = connection.description;
        } else {
          this.preview.url = url.length > 100 ? `${url.slice(0, 50)}...` : url;
        }

        this.selectedTaxEngineType =
          connection.engineType || connection.taxEngineType;
      }
    },
    groupId(value) {
      let results = this.testDecks.filter((t) => t.id === value);
      if (results.length > 0) {
        this.preview.numberOfTests = results[0]?.itemCount || '0';
        this.preview.testDescription = results[0]?.description || '';
      } else {
        this.preview.numberOfTests = null;
        this.preview.testDescription = null;
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleCancel() {
      this.modalShow = false;
    },
    handleRun() {
      const request = {
        groupId: this.groupId,
        connectivityId: this.connectivityId,
        transactionDate: this.transactionDate,
      };

      let connection = this.connections.find(
        (c) => c.id === this.connectivityId,
      );
      api.testing
        .execute(request, connection.engineType)
        .then(() => {
          this.showSuccess('Test Run successfully scheduled').then(() => {
            this.modalShow = false;
            this.$emit('refresh');
          });
        })
        .catch((err) => {
          this.$log.error('error', err);
          this.$swal({
            title: 'Error',
            icon: 'error',
            text: 'The test run could not be started, if the problem persists please contact support.',
            showCancelButton: false,
            confirmButtonText: 'OK',
          });
        });
    },
    fetchData() {
      this.isLoading = true;

      Promise.all([
        this.$store.dispatch('testing/fetchConnections'),
        this.$store.dispatch('testing/fetchTestDecks'),
      ]).finally(() => {
        this.$nextTick(() => {
          this.isLoading = false;
        });
      });
    },
  },
};
</script>
