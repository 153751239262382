<template>
  <div>
    <EntityDataTableCard
      v-if="canRunTests || entities.size > 0"
      id="test-results-list-table"
      title="Test Results"
      :loading="loading"
      :items="entities"
      :fields="fields"
      :per-page="25"
      export-disabled
      import-disabled
      add-disabled
      :table-filter="false"
      :table-filter-value.sync="tableFilterValue"
    >
      <template #custom-header-action>
        <CButton
          id="btn-test-result-refresh"
          class="btn-crd btn-add"
          variant="ghost"
          color="info"
          :title="
            autoRefreshing
              ? 'Auto-Refreshing'
              : loading
              ? 'Refreshing'
              : 'Refresh'
          "
          :disabled="loading"
          @click="fetchData()"
        >
          <CIcon
            name="cil-reload"
            :class="{ spinning: autoRefreshing || loading }"
          />
        </CButton>
        <MRunButton
          v-show="canRunTests"
          id="btn-create-new-test-run"
          class="mx-1"
          @click="handleCreate"
        />
      </template>
      <template #transactionGroupName="{ item }">
        <router-link
          v-if="item.runStatus.toUpperCase() === 'COMPLETED'"
          :id="`lnk-test-results-details_${item.id}`"
          :to="{ name: 'TestResults', params: { testRunId: item.id } }"
          >{{ item.transactionGroupName }}</router-link
        >
        <template v-else>
          {{ item.transactionGroupName }}
        </template>
      </template>
      <template #taxEngineTypeLabel="{ item }">
        <TaxEngineBadge :value="item.taxEngineType" />
      </template>
      <template #runStatus="{ item }">
        <CBadge :color="getRunStatusColor(item.runStatus)">{{
          item.runStatus
        }}</CBadge>
      </template>
      <template #custom-actions="{ item }">
        <MDeleteButton
          v-if="
            item.runStatus.toUpperCase() !== 'IN_PROGRESS' &&
            item.runStatus.toUpperCase() !== 'PENDING'
          "
          :id="`btn-delete-test-result_${item.id}`"
          class="mx-1"
          variant="outline"
          @click="handleDelete(item)"
        />
        <MDeleteButton
          v-else
          :id="`btn-delete-test-result_${item.id}`"
          class="mx-1"
          variant="outline"
          title="Cancel"
          @click="handleCancel(item)"
        >
          <CIcon name="cis-media-stop-circle" />
        </MDeleteButton>
        <MTestResultDownloadButton
          v-if="item.runStatus.toUpperCase() === 'COMPLETED'"
          :id="`btn-test-run-download_${item.id}`"
          :test-run-id="item.id"
          variant="outline"
          class="mx-1"
          title="Download"
        />
      </template>
    </EntityDataTableCard>
    <TestRunModal
      v-if="showModal"
      id="mod-test-run-modal"
      :show="showModal"
      @refresh="refreshTable"
      @update:show="
        (v) => {
          showModal = v;
        }
      "
    />
  </div>
</template>
<script>
import EntityDataTableCard from '@/components/EntityDataTableCard';
import MRunButton from '@/components/Buttons/MRunButton';
import TestRunModal from '@/views/testing/_components/_modals/TestRunModal';
import api from '@/api';
import { mapGetters } from 'vuex';
import { MTestResultDownloadButton } from '@/components/Buttons';
export default {
  name: 'RecentTestResultsTable',
  components: {
    EntityDataTableCard,
    MRunButton,
    TestRunModal,
    MTestResultDownloadButton,
  },
  data() {
    return {
      loading: false,
      entities: [],
      showModal: false,
      autoRefreshing: false,
      tableFilterValue: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['hasSingleTaxEngine']),
    ...mapGetters('testing', ['canRunTests']),
    fields() {
      let fields = [
        {
          key: 'transactionGroupName',
          label: 'Test Deck',
          sorter: true,
          filter: false,
        },
        {
          key: 'connectivityName',
          label: 'Connection',
          sorter: true,
          filter: false,
        },
        {
          key: 'taxEngineTypeLabel',
          label: 'Engine',
          hidden: this.hasSingleTaxEngine,
          toggleable: !this.hasSingleTaxEngine,
        },
        {
          key: 'totalTransactionCount',
          label: 'Count',
          filter: false,
          fieldType: 'NUMBER',
        },
        {
          key: 'errorTransactionCount',
          label: 'Failures',
          filter: false,
          fieldType: 'NUMBER',
        },
        {
          key: 'runStatus',
          label: 'Status',
          filter: false,
        },
        {
          key: 'startedAt',
          label: 'Executed At',
          filter: false,
          fieldType: 'DATE',
          format: 'yyyy-MM-dd HH:mm',
        },
        {
          key: 'custom-actions',
          label: '',
          sorter: false,
          filter: false,
          _classes: ['action-cell'],
        },
      ];

      if (this.hasSingleTaxEngine) {
        fields.splice(2, 1);
      }

      return Object.freeze(fields);
    },
  },
  watch: {
    canRunTests(v) {
      if (!v) {
        this.fetchData();
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    removeForTestDeck(testDeckId) {
      let filtered = this.entities.filter((d) => {
        return d.transactionGroupId !== testDeckId;
      });

      this.entities = filtered;
    },
    refreshTable() {
      this.fetchData();
    },
    getRunStatusColor(status) {
      switch (status?.toUpperCase()) {
        case 'COMPLETED':
          return 'success';
        case 'RETRIEVING':
        case 'PROCESSING RESULTS':
        case 'IN PROGRESS':
        case 'IN_PROGRESS':
          return 'info';
        case 'FAILED':
          return 'danger';
        case 'CANCELLED':
          return 'warning';
        default:
          return 'secondary';
      }
    },
    fetchData() {
      const self = this;
      this.loading = true;
      api.testing
        .getAllRecentResults()
        .then((data) => {
          let needsReload = false;
          this.entities = (data || []).map((o) => {
            switch (o.runStatus) {
              case 'RETRIEVING':
              case 'IN_PROGRESS':
              case 'PENDING':
                needsReload = true;
                break;
            }

            return Object.freeze({
              ...o,
              taxEngineType: o.engineType || o.taxEngineType,
              taxEngineTypeLabel: this.$t(
                `taxEngineType.${o.engineType || o.taxEngineType}`,
              ),
              runStatus: this.$t(`testRunStatus.${o.runStatus}`),
              errorTransactionCount:
                (o.invalidTransactionCount || 0) +
                (o.errorTransactionCount || 0),
            });
          });

          self.autoRefreshing = needsReload;
          if (needsReload) {
            setTimeout(() => {
              self.fetchData();
            }, 5000);
          }
        })
        .finally(() => {
          this.$nextTick(() => {
            this.loading = false;
          });
        });
    },
    handleCancel(testResult) {
      const self = this;
      this.$swal
        .fire({
          title: 'Confirm Cancel',
          text: 'Are you sure you wish to cancel this test run?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        })
        .then((res) => {
          if (res.isConfirmed) {
            api.testing
              .cancelTestRun(testResult.id, testResult.taxEngineType)
              .then(() => {
                const index = self.entities.findIndex((row) => {
                  return row.id === testResult.id;
                });

                if (index >= 0) {
                  self.entities.splice(index, 1, {
                    ...self.entities[index],
                    runStatus: this.$t('testRunStatus.CANCELLED'),
                  });
                }
                self.$emit('cancel', testResult.id);
              })
              .catch((err) => {
                this.$log.error(err);
                this.$swal.fire({
                  title: 'Error',
                  text: 'The test run could not be cancelled',
                  icon: 'error',
                });
              });
          }
        });
    },
    handleDelete(testResult) {
      const self = this;
      this.$swal
        .fire({
          title: 'Confirm Delete',
          text: 'Are you sure you wish to delete this test run?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        })
        .then((res) => {
          if (res.isConfirmed) {
            api.testing
              .deleteTestRunResult(testResult.id, testResult.taxEngineType)
              .then(() => {
                const index = self.entities.findIndex((row) => {
                  return row.id === testResult.id;
                });

                if (index >= 0) {
                  self.entities.splice(index, 1);
                }

                self.$emit('remove', testResult.id);
              })
              .catch((err) => {
                this.$log.error(err);
                this.$swal.fire({
                  title: 'Error',
                  text: 'The test run could not be deleted',
                  icon: 'error',
                });
              });
          }
        });
    },
    handleCreate() {
      this.showModal = true;
    },
  },
};
</script>
<style lang="scss">
.c-icon.spinning {
  animation: spin 2s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
